export const fibers_edge_over_copper = {
    meta_title: "Fiber vs. Copper: Discover speed & reliability with Niurix",
    meta_description: " Niurix fiber delivers blazing speeds, unmatched reliability, and incredible bandwidth, far exceeding copper. Say bye to limitations and hello to fiber optic.",
    section1: {
        banner: "fibers_edge_over_copper_banner",
        banner_mobile: "Fiber's Edge Over Copper banner",
        alt:"Fiber-optic network for edge in communications over copper",
        title: "Fiber's Edge Over Copper",
    },

    section2: {
        heading: `Superiority in <span>Connectivity</span> and <span>Performance</span>`,
        para: `At Niurix, "Fiber's Edge Over Copper" is more than just a slogan; it's a commitment to shattering the boundaries of conventional copper solutions. By harnessing the unparalleled speed, reliability, and bandwidth of fiber-optic technology, we ensure that your connectivity isn't merely improved; it's future-proofed against the ever-evolving demands of technology.`,
        img: "fibers_edge_over_copper",
        alt:"Experience enhanced connectivity with fiber optics",
    },

    section3: {
        card_heading: "Three Key Factors of Our <span>Fiber's Edge</span> Over <span>Copper</span>",
        cards: [
            {
                number: "01",
                title: "Astounding Speeds",
                para: "Stay ahead of the curve with fiber-optic technology ensuring ultra-fast data transmission, outperforming copper substantially.",
                img: "Astounding-Speeds",
            },
            {
                number: "02",
                title: "Incredible Bandwidth",
                para: "Handle massive amounts of data effortlessly guaranteeing that every user on your network enjoys optimal performance.",
                img: "Incredible-Bandwidth",
            },
            {
                number: "03",
                title: "Enhanced Reliability",
                para: "Less susceptibility to environmental and external interferences ensures your connectivity stays consistent and robust.",
                img: "Enhanced-Reliability",
            },
        ],
    },
};
