export const optimized_fiber_optic_solution = {
    meta_title: "Customize your network with Niurix's optimal fiber solutions",
    meta_description: "No more 'one-size-fits-all' fiber! Niurix designs personalized solutions for home & businesses. Stream, game, work - do it all without limits. Explore Niurix.",
    section1: {
        banner: "optimized_fiber_optic_solution_banner",
        banner_mobile: "Optimized Fiber-Optic Solution baanner",
        alt:"Fiber-optic network solution optimizing data transmission",
        title: "Optimized Fiber-Optic Solution",
    },

    section2: {
        heading: `<span>Unified Connectivity</span> Simplified`,
        para: "In today's world of boundless possibilities in the digital landscape, the Niurix GPON solution transforms connectivity to extraordinary. Picture a single fiber-optic cable that functions as the key to unlocking a myriad of services, steering an era where diverse communication channels seamlessly converge into one elegantly streamlined solution for providing a seamless and simplified user experience.",
        img: "optimized_fiber_optic_solution",
        alt:"Niurix connected to network devices using fiber-optics",
    },

    section3: {
        card_heading: `Three Key Factors of Our <span>Optimized Fiber-Optic</span> Solution`,
        cards: [
            {
                number: "01",
                title: "Single Fiber, Multiple Services",
                para: "Simplify infrastructure and management by delivering data, voice, and video services through one cable.",
                img: "Single Fiber Multiple Services",
            },
            {
                number: "02",
                title: "Reduced Infrastructure Costs",
                para: "Reduce physical infrastructure demands and expenses by minimizing the need for extensive wiring.",
                img: "Reduced-Infrastructure-Costs",
            },
            {
                number: "03",
                title: "Enhanced Efficiency",
                para: "Streamline service delivery and maintenance with an integrated and uncomplicated setup.",
                img: "Enhanced-Efficiency",
            },
        ],
    },
};
