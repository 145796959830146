export const software = {
    meta_title: "Smarter Fiber Networks and Real-Time Monitoring with Niurix",
    meta_description: "See your fiber soar with Niurix. Get real-time insights with ONT monitoring, centralized access for protection and zero lag services for smooth experience.",

    section1: {
        img: "",
        title: "Fiber for the Future: Advancing Connectivity and Control for the Users Today.",
        alt:"Fiber-optics for reliable connectivity and fast connectivity",
    },
    section2: {
        title: `Beyond Copper: Unleashing the <span>network technology</span> of tomorrow`,
        para: "Bid farewell to tangled cables and embrace the future of networking with Niurix. A fiber optic solution for lightning-fast data transmission, unwavering reliability and connectivity for your business or residence, Niurix enhances the user experience and offers uninterrupted triple play services. With a sleek, space-efficient design, our technology seamlessly blends into your environment while uplifting your network capacity.",

        cards: [
            {
                id: 1,
                card_img: "Centralized_login",
                card_img_mobile: "Centralized login and Access control Mobile",
                alt: "Enhance security of fiber network with centralized control",
                card_title: "Centralized Login and Access control",
                card_para:
                    "Strengthen the security of your fiber-based network infrastructure and streamline user access with centralized login and access control. With user pertinent access, ensure only authorized personnel entry and simplify user management.",
            },
            {
                id: 2,
                card_img: "Streamlined_OLT_and_ONT",
                card_img_mobile: "Streamlined OLT and ONT Mobile",
                alt: "Fiber network with seamless connectivity and data transfer",
                card_title: "Streamlined ONT and OLT",
                card_para:
                    "Seamless connectivity and faster data transmission are the pinnacle of an efficient network. Niurix delivers performance, scalability, and operational savings you need for uninterrupted video, voice, and data streaming with a reliable network.",
            },
            {
                id: 3,
                card_img: "Realtine_ONT_Monitoring",
                card_img_mobile: "Realtime ONT Monitoring Mobile",
                alt:"Optimize network performance by monitoring ONTs in real-time",
                card_title: "Real-time ONT Monitoring",
                card_para:
                    "Track your network's performance, detect potential issues, and optimize resources with real-time ONT monitoring. Stay in control of your network like never before and experience proactive management for simplified work. ",
            },
            {
                id: 4,
                card_img: "Network_Topology",
                card_img_mobile: "Network Topology Visualization Mobile",
                alt:"View fiber network in real-time and make informed decisions",
                card_title: "Network Topology Visualization",
                card_para:
                    "Gain a deeper, real-time insight into your fiber-based network infrastructure and make informed decisions like a breeze. Understand the intricate connections and identify bottlenecks effortlessly with our network visualization tools.",
            },
            {
                id: 5,
                card_img: "Remote_monitoring_and_management",
                card_img_mobile: "Remote mon Mobile",
                alt:"Seamless fiber optic network operation via remote monitoring",
                card_title: "Remote Monitoring and Management",
                card_para:
                    "Monitor and track your devices and network infrastructure from anywhere and anytime on the go. Stay in control and ensure a reliable network operation seamlessly with remote monitoring for ONT and OLT.",
            },
        ],
    },

    section3: {
        img: "",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    },
};
