import React from "react";

const BlogsContainer = () => {
  return (
    <main>
      <h1>Blogs</h1>
    </main>
  );
};

export default BlogsContainer;
