const headerData = {
    solutions: [
        {
            page: "solutions",
            name: "Optimized Fiber-Optic Solution",
            image: "Optimized Fiber-Optic Solution",
            url: "/solutions/optimized-fiber-optic-solution",
        },
        {
            page: "solutions",
            name: "Fiber's Edge Over Copper",
            image: "Fibers Edge Over Copper",
            url: "/solutions/fibers-edge-over-copper",
        },
        {
            page: "solutions",
            name: "Scalable and Future Ready Design",
            image: "Scalable and Future-Ready Design",
            url: "/solutions/scalable-and-future-ready-design",
        },
        {
            page: "solutions",
            name: "Configurations and Personalized Support",
            image: "Configurations and Personalized Support",
            url: "/solutions/configurations-and-personalized-support",
        },
    ],
    products: [
        {
            page: "products",
            type: "ONT",
            modelNum: "P4200R",
            // name: "ONT - P4200R",
            image: "ONT P4200r",
            url: "/products/ONT-P4200R",
        },
        {
            page: "products",
            type: "ONT",
            modelNum: "G2410",
            // name: "ONT- G2410",
            image: "ONT g2410",
            url: "/products/ONT-G2410",
        },
        {
            page: "products",
            type: "OLT",
            modelNum: "SOLT33- 8P",
            // name: "OLT - SOLT33- 8P",
            image: "OLT SOLT33- 08P",
            url: "/products/OLT-SOLT33-8P",
        },
        {
            page: "products",
            type: "OLT",
            modelNum: "SOLT33- 16P",
            // name: "OLT -  SOLT33- 16P",
            image: "OLT SOLT33-16P",
            url: "/products/OLT-SOLT33-16P",
        },
    ],
    industries: [
        {
            page: "industries",
            name: "Hospitality",
            image: "HOSPITALITY",
            url: "/industries/hospitality",
        },
        {
            page: "industries",
            name: "Corporate Workspaces",
            image: "CORPORATE",
            url: "/industries/corporate-workspaces",
        },
        {
            page: "industries",

            name: "Residential Real Estate",
            image: "Residential real estate",
            url: "/industries/residential-real-estate",
        },
        {
            page: "industries",

            name: "Student Living",
            image: "Student living",
            url: "/industries/student-living",
        },
    ],
};

export default headerData;
