import React from "react";
import Styles from "./niurixparallax.module.css";

import { ReactComponent as Rightarrow } from "../../../assets/homepage/RightArrow.svg";
import { Link } from "wouter";
import { Helmet } from "react-helmet-async";
function Niurixparallax() {
  return (
<>
<Helmet>
                <title>Transform building networks with the power of fiber-optics</title>
                <meta name="description" content="Experience our end-to-end GPON solution with remote management and monitoring. Amplify the potential of network with high throughput and low latency." />
                <link rel="canonical" href={`https://niurix.com/`}></link>
            </Helmet>



    <div className={Styles.parallax_img_padding}>
      <div className={Styles.parallax_img_wrapper} >
        <div className={Styles.parallax_desc_contactus_wrapper}>
          <div className={Styles.parallax_text_content}>
            Transform Your Network Architecture With Us!
          </div>
          <Link href="/contact-us">
            <div className={Styles.parallax_contact_us}>
              Contact us <Rightarrow className={Styles.rightarrow_parallax} />
            </div>
          </Link>
        </div>
      </div>
    </div>
    </>
  );
}

export default Niurixparallax;
