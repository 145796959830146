export const scalable_and_future_ready_design = {
    meta_title: "Grow boundless: Niurix's fiber solution built for tomorrow",
    meta_description: "Niurix's scalable fiber designs ensure your infrastructure keeps pace with your bandwidth demands. Connect confidently, connect for the future.",
    section1: {
        banner: "scalable_and_future_ready_design_banner",
        banner_mobile: "Scalable andFuture-Ready Design banner",
        alt:"Fiber-optics makes networks scalable and future ready",
        title: "Scalable and Future -Ready Design",
    },

    section2: {
        heading: "Gearing Up <span>for the Future</span>",
        para: "Scalable and Future-Ready Design is more than just a philosophy - it is the foundation upon which Niurix bases its GPON solution. Every feature, from its modular structure to its adaptability, has been meticulously designed to guarantee that it will not only be in line with present needs but will also be steadfastly prepared for future technological advancement.",
        img: "scalable_and_future_ready_design",
        alt:"Unleash the power of scalability with fiber optic network",
    },

    section3: {
        card_heading: "Three Key Factors of Our <span>Scalable</span> and <span>Future-Ready Design</span>",
        cards: [
            {
                number: "01",
                title: "Modular Design",
                para: "Components can be easily added, changed, or upgraded to meet changing demands without completely redoing the system.",
                img: "Modular Design",
            },
            {
                number: "02",
                title: "Adaptive Capabilities",
                para: "Engineered to nimbly adapt to changing consumer needs and technological improvements.",
                img: "Adaptive Capabilities",
            },
            {
                number: "03",
                title: "Seamless Expansion",
                para: "Easy scalability that ensures your network grows in tandem with your requirements, without compromising performance.",
                img: "Seamless Expansion",
            },
        ],
    },
};
