export const homePage = {
    meta_title: "Plug into the future with Niurix: Fiber Optic for next gen",
    meta_description: "Niurix delivers future-proof connectivity! Experience super-fast fiber optic internet network offering remote monitoring, low energy consumption and easy setup.",

    banner_content: [
        {
            img: "Optimized _Fiber_Optic _Solution",
            img_mobile: "Optimized Fiber-Optic Solution baanner ",
            alt:"Redefine network communications with fiber optic solution",
            url: "optimized-fiber-optic-solution",
            title: "Optimized Fiber-Optic Solution",
            para: "Redefine Efficiency with Single Cable for Multiple Services",
            button: `  <div><Link to="/"><span>Explore</span></Link></div>`,
        },
        {
            img: "Fibers_Edge_Over_Copper",
            img_mobile: "Fiber's Edge Over Copper banner",
            alt:"Get edgy on network performance with fiber optic solution",
            url: "fibers-edge-over-copper",
            title: "Fiber's Edge Over Copper",
            para: "Experience Next-Level Connectivity with Fiber Over Copper",
            button: `  <div><Link to="/"><span>Explore</span></Link></div>`,
        },
        {
            img: "Scalable_and_Future_Ready_Design",
            img_mobile: "Scalable andFuture-Ready Design banner",
            alt:"Boost the potential of device connections with fiber network",
            url: "scalable-and-future-ready-design",
            title: "Scalable and Future-Ready Design",
            para: "Custom Solutions Ready for Today, Designed for Tomorrow",
            button: `  <div><Link to="/"><span>Explore</span></Link></div>`,
        },
        {
            img: "Configurations _and_Personalized_Support",
            img_mobile: "Configurations and Personalized Support",
            alt:"Contact our experts for configuration and support assistance",
            url: "configurations-and-personalized-support",
            title: "Configurations and Personalized Support",
            para: "Customized Connectivity and Support, Your Way",
            button: `<div><Link to="/"><span>Explore</span></Link></div>`,
        },
    ],

    hw_cards: {
        title: "Empowering Spaces with High-Performance GPON Fiber Solutions ",
        hwcard_items: [
            {
                text: "End to end Gpon solution",
            },
            {
                text: "Robust Security Protocols",
            },
            {
                text: "Remote Management and monitoring",
            },
            {
                text: "High Throughput and Low latency",
            },
        ],
    },

    building_video: [
        {
            // title:"lorem ipsum dolor sit amet, consectetur",
            img2: "sub content building img",
        },
    ],
    data_count: [
        {
            count: "80%",
            desc: "Reduction in Energy Consumption ",
        },
        {
            count: "30%",
            desc: "Reduction in Operational Expenses",
        },
        {
            count: "95%",
            desc: "Faster Incident Detection and Resolution",
        },
        {
            count: "90%",
            desc: "Reduction in Latency",
        },
    ],
    niurix_products: [
        {
            // name: "P4200R",
            // img: "P4200R",
            prod: "P4200R",
            type: `<span>(ONT)</span>`,
            desc: "The Niurix P4200R (Optical Network Terminal) is a full-feature ONT exclusively designed for triple-play services (internet, television, and voice). Typically consisting of 4 Gigabit Ethernet(GbE)/PoE ports can be used extensively for connecting multiple devices over the internet.",
            url: "/products/ONT-P4200R",
            alt:"Niurix P4200R full-feature ONT, triple-play, 4 GE/PoE ports"
        },
        {
            // name: "G2410",
            // img: "G2410",
            prod: "G2410",
            type: ` <span>(ONT)</span>`,
            desc: "The Niurix G2410 ONT (Optical Network Terminal) is a lightweight, compact, and high-performance networking device with four Gigabit Ethernet(GbE) ports. This ONT is engineered to bridge the communication between modern technological infrastructure and end-users.",
            url: "/products/ONT-G2410",
            alt:"Niurix G2410 ONT, lightweight, compact with 4 GE ports"
        },
        {
            // name: "SOLT33-8P",
            // img: "SOLT33-08P",
            prod: "SOLT33-08P",
            type: `<span>(OLT)</span>`,
            desc: "The Niurix SOLT33-08P is an Optical Line Terminal (OLT) designed for GPON networks serving up to 128 Optical Network Terminals (ONTs) per PON port. It consists of eight GPON ports, 4 GE Optical/Electrical uplink ports, along with two 10 Gigabit Ethernet (10GE) uplink ports.",
            url: "/products/OLT-SOLT33-8P",
            alt:"Niurix SOLT33-08P, 128 ONT/PON port, 8 GPON, 4 GE, 2x10 GE"
        },
        {
            // name: "SOLT33-16P",
            // img: "SOLT33-16P",
            prod: "SOLT33-16P",
            type: `<span>(OLT)</span>`,
            desc: "The Niurix SOLT33-16P is another Optical Line Terminal designed with 16 GPON ports that can be connected to ONTs. Its primary distinction from the SOLT33-08P is its increased number of GPON ports making it suitable for larger deployment for FTTB.",
            url: "/products/OLT-SOLT33-16P",
            alt:"Niurix SOLT33-16P OLT, 16 GPON ports, large scale FTTB"
        },
    ],

    our_industries: [
        {
            id: 1,
            img: "Hospitality",
            img1: "hospitality-industries-cropped",
            alt:"Redefine hospitality business with fiber optic network",
            title: "Hospitality",
            desc: "Catering to Exceptional Guest Experiences: Pioneering Next-Gen Networking Solutions to Redefine Hospitality",
            url: "Hospitality",
            url_link: "hospitality",
            mob_crop_img: "Hosp 1 home",
            mob_img: "Hosp 2 home",
        },
        {
            id: 2,
            img: "Residential",
            img1: "resident-industries-cropped",
            alt:"Go hassle free digital in residential spaces with fiber",
            title: "Residential Real Estate",
            desc: "Reinventing Real-Estate Connectivity: Delivering Cutting Edge Fiber Solutions to Residential Spaces",
            url: "Residential Real Estate",
            url_link: "corporate-workspaces",
            mob_crop_img: "resident  1 home",
            mob_img: "res 2 home",
        },
        {
            id: 3,
            img: "Corporate",
            img1: "corporate-industries-cropped",
            alt:"Boost connectivity in commercial spaces with fiber optics",
            title: "Corporate Workspaces",
            desc: "Corporate Workspaces that Work: Reimagine Connectivity Solutions for Modern Workforce",
            url: "Corporate Workspaces",
            url_link: "residential-real-estate",
            mob_crop_img: "corp 1 home",
            mob_img: "Corp 2 home",
        },
        {
            id: 4,
            img: "Student living",
            img1: "student-living-cropped",
            alt:"Fiber optics for student spaces with hassle-frees learning",
            title: "Student Living",
            desc: "Fiber Optic Solutions for Learning: Get the Speed You Need to Succeed",
            url: "Student Living",
            url_link: "student-living",
            mob_crop_img: "student 1 home",
            mob_img: "Student 2 home",
        },
    ],

    install_content: [
        {
            title: "Assess Environment",
            url: "",
            para: "We commence by evaluating your network environment to identify the optimal placement for your ONT and OLT units. We take into account factors like signal strength and accessibility, facilitating smoother amalgamation of existing business systems.",
        },
        {
            title: "Secure Mounting",
            url: "",
            para: "Next, we securely mount the units and connect the fiber optic cables to their designated ports, ensuring clean and precisely aligned connections. This ensures the seamless integration of all assets within your network frame. ",
        },
        {
            title: "Automating Connectivity",
            url: "",
            para: "Once installed, Niurix easily adapts to your network, ensuring effortless connectivity and peak performance. You now have access to a secure, powerful, and scalable solution to handle your network architecture better.  ",
        },
    ],

    //merge
    blog_heading: {
        title: "Blogs",
        //blogs swipper
        blogs_content_swipper: [
            {
                imageName: "Blog1",
                title: "lorem ipsum dolor sit amet , jshshsh...",
                url: "",
                date: "Dec 20, 2022",
                subtitle: "lorem ipsum diuhcsusccsgsg hhdhhdh",
            },
            {
                imageName: "Blog2",
                title: "lorem ipsum dolor sit amet , jshshsh...",
                url: "",
                date: "Dec 20, 2022",
                subtitle: "lorem ipsum diuhcsusccsgsg hhdhhdh",
            },
            {
                imageName: "Blog3",
                title: "lorem ipsum dolor sit amet , jshshsh...",
                url: "",
                date: "Dec 20, 2022",
                subtitle: "lorem ipsum diuhcsusccsgsg hhdhhdh",
            },
        ],
    },
    //paralax
    parallax_content: [
        {
            // img:"homepage- contact",
            desc: "Transform Your Network Architecture With Us!",
            //url
            url: "",
        },
    ],
};
